import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import device from "../components/device"
import SecondaryHeader from "../components/header/header-secondary"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { transformURLs } from "../components/utils/transformURLs"

const Wrapper = styled.div`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }

  .content {
    max-width: 1200px;
    width: 70%;
    margin: 5rem auto 10rem auto;
    ${device.small`width: var(--spread);`}

    p {
      font-size: 1rem;
      margin: 1rem 0;
    }

    a {
      font-size: 1rem;
      color: var(--green);
      text-transform: none;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 2rem 0;
      text-align: center;
    }

    h1 {
      font-size: 1.5rem;
      color: var(--grey);
    }

    h3 {
      font-size: 3rem;
      color: var(--green);
      font-family: "Great Vibes", cursive;
      font-style: normal;
      text-transform: none;
      ${device.small`font-size: 2.5rem;`}
    }

    h2 {
      font-size: 2rem;
      ${device.small`font-size: 1.5rem;`}
    }
  }
`

const BlogPage = ({ data }) => {
  const { title, content } = data.wordpressPost

  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Layout>
        <Helmet>
          <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.post.seo.title}`}</title>
          <meta
            name="description"
            content={data.wpgraphql.post.seo.opengraphDescription}
          />
          <meta
            property="og:url"
            content={data.wpgraphql.post.seo.opengraphUrl}
          />
          <meta
            property="og:site_name"
            content={data.wpgraphql.post.seo.opengraphSiteName}
          />
          <meta property="og:title" content={data.wpgraphql.post.seo.title} />
          <meta
            property="og:description"
            content={data.wpgraphql.post.seo.opengraphDescription}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Twitter */}
          <meta
            name="twitter:title"
            content={data.wpgraphql.post.seo.twitterTitle}
          />
          <meta
            name="twitter:description"
            content={data.wpgraphql.post.seo.twitterDescription}
          />

          <meta name="geo.region" content="US-PA" />
          <meta name="geo.placename" content="King of Prussia" />
          <meta name="geo.position" content="40.091710;-75.346160" />
          <meta name="ICBM" content="40.091710, -75.346160" />
        </Helmet>
        <Wrapper>
          <div className="secondary-header-wrapper">
            <SecondaryHeader />
          </div>
          <div className="content">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: transformURLs(content) }} />
          </div>
        </Wrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!, $id: ID!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      path
      content
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    wpgraphql {
      post(id: $id, idType: DATABASE_ID) {
        author {
          node {
            firstName
            lastName
          }
        }
        content
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
          opengraphImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              sourceUrl
              date
              description
              title
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
        tags {
          nodes {
            name
          }
        }
        categories {
          nodes {
            name
          }
        }
        date
        modified
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
  }
`

export default BlogPage
